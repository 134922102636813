import "./footer.css";
import logo from "../images/logo.png";
import instagram from "../images/instagram.svg";
import facebook from "../images/facebook.svg";
import google from "../images/google.svg";
import youtube from "../images/youtube.svg";
import location from "../images/location.png";
import phone from "../images/call.png";
import email from "../images/mail.png";

function Footer() {
  return (
    <footer className="footer_main">
      <div className="footer_subdiv">
        <div className="logo_left">
          <img src={logo} alt="logo" width={135} height={48} />
          <div className="social_icons">
            <a href="https://www.instagram.com/cafe.coromandel" target="_blank">
              <img src={instagram} height={28} width={28} alt="instagram" />
            </a>
            <a href="https://m.facebook.com" target="_blank">
              <img src={facebook} height={28} width={28} alt="facebook" />
            </a>
            <a href="https://cafecoromandel.com/" target="_blank">
              <img src={google} height={28} width={28} alt="google" />
            </a>
            <a href="https://www.youtube.com" target="_blank">
              <img src={youtube} height={28} width={28} alt="youtube" />
            </a>
          </div>
        </div>

        <div className="info_right">
          <div className="location">
            <img src={location} height={20} width={20} alt="location" />
            <span className="location_item">
              Ground Floor of Blooms Medicity, 206P,<br></br>
              Sector 38, Gurgaon, Haryana 122022
            </span>
          </div>
          <div className="location">
            <img src={phone} height={20} width={20} alt="location" />
            <span className="location_item">+91 96677 98451</span>
          </div>
          <div className="location">
            <img src={email} height={20} width={20} alt="location" />
            <span className="location_item">info@houzey.in</span>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <span className="footer_text">Copyright © 2024 Cafe Coromandel</span>
      </div>
    </footer>
  );
}

export default Footer;
