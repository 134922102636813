// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/App.css */


body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  /* overflow-x: hidden; */
}

:root {
  --black: #000;
  --color-orange: #F25325;
}
* {
  box-sizing: border-box;
}








`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,gBAAgB;;;AAGhB;EACE,SAAS;EACT,gCAAgC;EAChC,wBAAwB;AAC1B;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,sBAAsB;AACxB","sourcesContent":["/* src/App.css */\n\n\nbody {\n  margin: 0;\n  font-family: 'Arial', sans-serif;\n  /* overflow-x: hidden; */\n}\n\n:root {\n  --black: #000;\n  --color-orange: #F25325;\n}\n* {\n  box-sizing: border-box;\n}\n\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
