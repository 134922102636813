import './About.css';

const About = () => {
  return (
    <div className='main_div'>
        <h3 className='about_text' id="about">About Us</h3>
       <p className='about_para'>Coromandel Cafe was born out of a passion for creating a community 
       space where people could come together to savor the finest delectable bites & beverages. 
       Inspired by the rich history and vibrant culture of the Coromandel Coast, our cafe strives
        to blend tradition with modernity, offering 
       a unique fusion that sets us apart. Inspired by the rich history and 
       vibrant culture of the Coromandel Coast.</p> 
    </div>
  );
};

export default About;
