// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2{

color: var(--black, #000);
text-align: center;
font-family: Spectral;
font-size: 32px;
font-style: normal;
font-weight: 500;
line-height: 40px; /* 125% */
text-transform: capitalize;
}

`, "",{"version":3,"sources":["webpack://./src/map/Map.css"],"names":[],"mappings":"AAAA;;AAEA,yBAAyB;AACzB,kBAAkB;AAClB,qBAAqB;AACrB,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB,EAAE,SAAS;AAC5B,0BAA0B;AAC1B","sourcesContent":["h2{\n\ncolor: var(--black, #000);\ntext-align: center;\nfont-family: Spectral;\nfont-size: 32px;\nfont-style: normal;\nfont-weight: 500;\nline-height: 40px; /* 125% */\ntext-transform: capitalize;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
