// src/components/Navbar.js
import React from 'react';
import './Navbar.css';
import logo from "../images/logo.png";
 //import { PDFViewer } from '@react-pdf/renderer';
//import MyDocument from './Pdf/pdf';

const Navbar = () => {
  return (

    <nav className="navbar">
      <div className="logo_div">
        <img className="logo" src={logo} alt="logo" width={135} height={48} />
      </div>
      <ul className="nav-links" >
        <li><a href="tel:9667798451">966-779-8451</a></li>
        <li><a href='https://d3ebumlty7anr5.cloudfront.net/cafe_coromandel_ird_menu_v1.pdf' target="_blank">Menu</a></li>
      </ul>
    </nav>
  );
};

export default Navbar;
