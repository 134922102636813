// src/App.js
import React from 'react';
import './App.css';
import Carousel from './Carousel_components/Carousel';
import Navbar from './Navbar_components/Navbar';
import Footer from './Footer_Components/Footer';
import About from './about/About';
import  Testimonial from './testimonial/Testimonial';
import Map from './map/Map';
//import { useJsApiLoader } from '@react-google-maps/api';
//import { mapOptions } from './map/MapConfiguration';
//import { PDFViewer } from '@react-pdf/renderer';
//import MyDocument from './Pdf/pdf';
function App() {
  
  return (
    <div className="App">
      <Navbar/> 
      <Carousel />
      <About />
      <Testimonial />
      <Map />
      <Footer />
    </div>
  );
}





export default App;



