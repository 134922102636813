import "./Testimonial.css";

import Star1 from "../images/Star1.png";
import Star5 from "../images/Star5.png";
import Arrow1 from "../images/Arrow1.svg";
import Arrow2 from "../images/Arrow2.svg";

const Testimonial = () => {
  return (
    <div className="main_div">
      {/* Your content goes here */}

      <h3 className="text" id="testimonial">
        Testimonial
      </h3>
      <div className="testimonial_div">
        <img
          className="Arrow"
          src={Arrow2}
          height={22.957}
          max-width={22.957}
          alt="Arrowleft"
        />
        <div className="container">
          <frames className="section">
            <img
              className="face"
              src="https://lh3.googleusercontent.com/a-/ALV-UjWn6AHw8HM01x-AKzCuecjawtaw0wn_6EsfNH-cdB_lxCc=w80-h80-p-rp-mo-ba5-br100"
             
              alt="img3"
            />

            <h3>Sidharth Aggarwal</h3>
            <div className="star">
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star5" />
            </div>
            <p>
              “Excellent Vegetarian Thali, Chicken Sukka, Appam and Filter
              coffee”
            </p>
          </frames>

          <frames className="section">
            <img
              className="face"
              src="https://lh3.googleusercontent.com/a-/ALV-UjXyS-9WmPrAnReZjU7oYHyTAI-3MhvmPdCWTB_WRS8jjFg=w90-h90-p-rp-mo-br100"
             
              alt="img2"
            />
            <h3>Stuti priya</h3>
            <div className="star">
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
            </div>
            <p>
              “Totally in love with this new cafe in ggn.Do visit to cherish the
              ultimate south indian cuisine.”
            </p>
          </frames>

          <frames className="section">
            <img
              className="face"
              src="https://lh3.googleusercontent.com/a-/ALV-UjUQXSw1BMs62w-AZ8WnDJi-HdXkd7ZR7-GVMp-UslPhSTAj=w80-h80-p-rp-mo-ba4-br100"
             
              alt="img1"
            />
            <h3>Deepak Sakharkar</h3>
            <div className="star">
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star1" />
              <img src={Star1} alt="star5" />
              <img src={Star5} alt="star5" />
            </div>
            <p>
              “Very helpful and supportive staff, good food and atmosphere is
              very homely. I enjoyed my stay...”
            </p>
          </frames>
        </div>

        <img
          className="Arrow"
          src={Arrow1}
          height={22.957}
          max-width={22.957}
          alt="Arrowleft"
        />
      </div>
      <div className="btn-box">
        <button>
          <a
            href="https://www.google.com/search?q=cafe+coromandel+google&oq=cafe+coromandel+google+&gs_lcrp=EgZjaHJvbWUyBggAEEUYOTIHCAEQIRigATIHCAIQIRigAdIBCTEwNzAyajBqN6gCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x390d195c2670e123:0xe08594e80fba2b8a,3"
            target="_blank"
          >
            Write a Review
          </a>
        </button>
      </div>
    </div>
  );
};

export default Testimonial;
