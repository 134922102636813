import React from "react";
import "./Map.css";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
//import { mapOptions } from './MapConfiguration';

const Map = (props) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBzwJYuAnccGiIe3N9uOHCi6D8RmXJgbgo",
  });

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 28.43678,
    lng: 77.04694,
  };
  return (
    isLoaded && (
      <>
        <GoogleMap
          disableDefaultUI={true}
          mapContainerStyle={containerStyle}
          center={center}
          zoom={16}
          
        >
        <Marker position={center} title="Coromandel Cafe"></Marker>
        
        </GoogleMap>
      </>
    )
  );
};

export default Map;
