// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_div {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.about_text {
  color: var(--Black, #0f111d);
  text-align: center;
  font-family: "Spectral", serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 125% */
  text-transform: capitalize;
}

.about_para {
  width: 700px;
  display: inline-flex;
  color: var(--Black, #0f111d);
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: "Spectral", serif;
  font-size: 18px;
  font-style: italic;
  font-weight: 600;
  line-height: 30px;
  margin-left: 100px;
  margin-right: 100px;
  /* 166.667% */
}
@media screen and (max-width: 430px) {
  .main_div {
    text-align: center;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .about_para {
    width: 100%;
    align-items: center;
    margin-left:0px;
    margin-right:0px;
    box-sizing: border-box;
  }
}
`, "",{"version":3,"sources":["webpack://./src/about/About.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,4BAA4B;EAC5B,kBAAkB;EAClB,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,SAAS;EACT,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,oBAAoB;EACpB,4BAA4B;EAC5B,kBAAkB;EAClB,iCAAiC;EACjC,8BAA8B;EAC9B,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf;AACA;EACE;IACE,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;EACtB;EACA;IACE,WAAW;IACX,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,sBAAsB;EACxB;AACF","sourcesContent":[".main_div {\r\n  text-align: center;\r\n  margin-top: 100px;\r\n  margin-bottom: 100px;\r\n}\r\n\r\n.about_text {\r\n  color: var(--Black, #0f111d);\r\n  text-align: center;\r\n  font-family: \"Spectral\", serif;\r\n  font-size: 32px;\r\n  font-style: normal;\r\n  font-weight: 600;\r\n  line-height: 40px;\r\n  /* 125% */\r\n  text-transform: capitalize;\r\n}\r\n\r\n.about_para {\r\n  width: 700px;\r\n  display: inline-flex;\r\n  color: var(--Black, #0f111d);\r\n  text-align: center;\r\n  font-feature-settings: \"liga\" off;\r\n  font-family: \"Spectral\", serif;\r\n  font-size: 18px;\r\n  font-style: italic;\r\n  font-weight: 600;\r\n  line-height: 30px;\r\n  margin-left: 100px;\r\n  margin-right: 100px;\r\n  /* 166.667% */\r\n}\r\n@media screen and (max-width: 430px) {\r\n  .main_div {\r\n    text-align: center;\r\n    margin-top: 100px;\r\n    margin-bottom: 100px;\r\n  }\r\n  .about_para {\r\n    width: 100%;\r\n    align-items: center;\r\n    margin-left:0px;\r\n    margin-right:0px;\r\n    box-sizing: border-box;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
